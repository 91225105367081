@import '../../sass/import.scss';

.Home {
    display: flex;
    flex-direction: column;
    color: $font-grey;

    
    .inactive {
      color: white;
      padding: $space-9;
      border-radius: 10px;
      background-image: none !important;  
      transition: background-color 1s ease-in-out;
    }

    h1 {
        margin: $space-8;
        @include viewport-small-only {
          margin: $space-6 $space-8 $space-8 $space-8;
        }
    }

    .mainBodySpacer {
      margin-top: $space-2;
      @include viewport-small-only {
        margin-top: $space-3;
      }
    }

    .Home_sectionTitle {
      font-size: $space-6;
      font-weight: 400;
    }

    .Home_resultCardSection {
      margin-top: $space-1;
      
      .Home_resultCards {
        width: auto;
        display: flex;
        margin-left: $space-1;
        justify-content: initial;
        flex-direction: row;
        align-items: center;
        height: 100%;
        position: relative;
        flex-wrap: wrap;


        @include viewport-small-only {
          width: auto;
          display: flex;
          margin-left: 0;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 100%;
          position: relative;
          overflow-y: scroll;
        }
      }
    }

    .Home_quickSearch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-image: linear-gradient(326deg, #168ff1 0%, #2164e0 74%);  
      padding: $space-9;    
      margin: $space-8;
      border-radius: 10px;
      box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.10);
      width: $space-1*3;

      @include viewport-small-only {
        width: auto;
        transform: none;
      }

      .Home_quickSearch_zipInput {
        display: inline-block;
        background: transparent;
        color: white;
        font-size: $space-7;
        max-width: $space-3 - 8px;
      }

      .Home_quickSearch_zipLabel {
        display: inline-block;
        border-bottom: 2px dashed white;
      }

      .Home_quickSearch_calendar {
        color: $font-grey;
        border: 2px solid white;
        border-radius: $space-9/2;
        
        input {
          color: $font-grey;
        }
        svg {
          color: $font-grey;
          line {
            stroke: $font-grey;
          }
          rect {
            stroke: $font-grey;
          }
        }
      }
    }

    .HorizontalScroll {     
        margin: $space-8;
        @include viewport-small-only {
          margin: $space-6 0 $space-8 $space-8;
        }

        max-width: 100%;
        height: $space-1 + $space-1 + $space-8;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .centeredGenres{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .genreList {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-left: $space-8;
        @include viewport-small-only {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0;
        }
      }
    }


}

