/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
    box-sizing: inherit;
}