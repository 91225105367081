//custom colors

$black-body: #040404;
$black-body-light:#282828;
$grey: #A2A2A2;
$amazon-blue: #0098CB;
$font-grey: #dbdbdb;
$card-bg:rgb(50,50,50);



/// neutral palette
/// -------------------------------------------------------
$palette__white	    :	#FFF !default;
$palette__neutral-050 : #FAF9F7 !default;
$palette__neutral-100	:	#FCF7EC !default;
$palette__neutral-200	:	#F0EBE0 !default;
$palette__neutral-300	:	#B8B2A7 !default;
$palette__neutral-500	:	#787362 !default;
$palette__neutral-700	:	#524B3D !default;
$palette__neutral-900	:	#27241D !default;
$palette__black	    :	$palette__neutral-900 !default;

/// primary palette
/// -------------------------------------------------------
$palette__sol	     : #FFD400 !default;
$palette__shore	   : #02BAC9 !default;
//$palette__sand	   : #F0EBE0 !default;
//$palette__atlantic : #044CB8 !default;
$palette__florida	 : #1477D7 !default;

//@TODO: build out the whole series from designer and update names

/// primary palette shades
/// -------------------------------------------------------
$palette__florida-900: #003C7C !default;
$palette__florida-700: #065FB6 !default;
$palette__florida-500: $palette__florida;
$palette__florida-300: #8ACCFF !default;
$palette__florida-100: #EEFAFF !default;

$palette__sol-900: #B58105 !default;
$palette__sol-700: #E5B500 !default;
$palette__sol-500: $palette__sol;
$palette__sol-300: #FFE773 !default;
$palette__sol-100: #FFFAE6 !default;

$palette__shore-900: #00535A !default;
$palette__shore-700: #007E89 !default;
$palette__shore-500: $palette__shore;
$palette__shore-300: #74D9E1 !default;
$palette__shore-100: #E1F7F9 !default;


/// UI system palette
/// -------------------------------------------------------
$palette__coral	   : #DE1416 !default;
$palette__grove    : #008A00 !default;
$palette__tangerine: #FEAA3D !default;

/// UI system palette shades
/// -------------------------------------------------------
$palette__coral-500    : $palette__coral;
$palette__coral-300    : #FF7979 !default;
$palette__coral-100    : #FFF4F4 !default;

$palette__grove-500    : $palette__grove;
$palette__grove-300    : #73C873 !default;
$palette__grove-100    : #F0FFF0 !default;

$palette__tangerine-500: $palette__tangerine;
$palette__tangerine-300: #FFD283 !default;
$palette__tangerine-100: #FCF7EC !default;


// color for ui highlights
$palette__highlighter : $palette__sol !default;

/// color roles | background-colors
/// -------------------------------------------------------
$color__page-background   : $palette__white !default;

/// color roles | typography
/// -------------------------------------------------------
$color__text              : $palette__neutral-900 !default; 		// default text color
$color__text--hint        : $palette__neutral-500 !default;		// ancillary (eg. disclaimers, legalese)
//$color__text--secondary   : $palette__neutral-700 !default;

/// color roles | status colors
/// -------------------------------------------------------
$color__news	    :	$palette__florida !default;
$color__promotion	:	$palette__sol !default;
$color__crisis	  :	$palette__coral !default;
$color__error     : $palette__coral !default;
$color__positive  : $palette__grove !default;
$color__negative  : $palette__coral !default;
$color__warning	  : $palette__tangerine !default;
$color__notification	: $palette__florida-900 !default;


/// color roles | links
/// -------------------------------------------------------
$color__link              : $palette__neutral-900 !default;
$color__link--hover       : $palette__florida !default;

/// color roles | ui
/// -------------------------------------------------------
$color__ui-accent            : $palette__florida !default;

$color__border               : $palette__neutral-300 !default;
$color__overlay              : rgba(0,0,0,0.4) !default;
