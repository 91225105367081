@import './sass/import';
// @import './sass/font-face/Inter.scss';
@import 'assets/fonts/Inter/inter.css';

html {
  font-family: Inter;
  background-color: $black-body-light;
}

body {
  font-family: Inter;
  scroll-behavior: smooth;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

.react-icons {
  vertical-align: top;
}