@import '../../sass/import.scss';

.Featured {
    height: max-content;
    @include viewport-small-only {
      height: $space-1*5;
    }

    .Slider {
        position: relative;
        height: 40vh;
        width: 100%;
        z-index: 0;
        margin-bottom: 20px;
        @include viewport-small-only {
          margin-top: -100px;
          height: 70%;
          width: 100%;
          z-index: 0;
          margin-bottom: 0px;
        }
    }

    a {
      color: white;    
    }

    .Featured_title {
        margin: $space-8;
        @include viewport-small-only {
          margin: $space-6 $space-8 0 $space-8;
        }
    }

    .Featured_description {
        margin: $space-8;
        @include viewport-small-only {
          margin: $space-9 $space-8 $space-8 $space-8;
        }
    }
}

