@import '../../sass/import.scss';

.MoviePage{
    color: white;

    .MoviePage_backButton {
        color: white;
        position: absolute;
        margin: $space-7 0 0 $space-9/2
    }

    .MoviePage_poster {
      object-fit: scale-down;
      width: 100%;
      height: 60vh;
      top: 0;
      left: 0;

        @include viewport-small-only {
          max-width: 100vw;
          height: auto;
        }
    }

    .MoviePage_textBlock {
        margin: $space-8;

        .MoviePage_title {
            font-size: $space-3;
            margin: 0;

            @include viewport-small-only {
              font-size: $space-4*.75;
            }
        }
    
        .MoviePage_rating {
            font-size: $space-7;
            margin: 0;
            margin-bottom: $space-9;
        }
    
        .MoviePage_description {
            font-size: $space-8;
            margin: 0;
        }
    
        .MoviePage_timesTitle {
            margin-top: $space-7;
            margin-bottom: 0;
            padding: 0;
            display: block;
        }
    
        .MoviePage_timeSlots {
            margin: 0;
            margin-top: $space-7;
            max-width: 100vw;
            width: calc(100vw - 16px);
            display: flex;
            flex-wrap: wrap;
            justify-content: initial;

            .theater_block {
              width: 100%;
              margin-bottom: $space-8;
  
              .times_theater{
                  line-height: $space-6;
                  font-size: $space-6;
                  font-weight: 700;
                  display: block;
                  margin-bottom: 0;
              }
  
              .times_theaterDistance{
                  line-height: $space-8;
                  font-size: $space-8;
                  font-weight: 400;
                  vertical-align: middle;
                  margin-bottom: $space-9;
              }
  
              .times_allTimes {
                  display: flex;
                  justify-content: initial;
                  align-items: center;
                  flex-wrap: wrap;
  
                  .MoviePage_timeSlot {
                      width: max-content;
                      font-size: $space-8;
                      line-height: $space-9;
                      border: 2px solid white;
                      border-radius: 5px;
                      margin-right: 2px;
                      margin-top: $space-8;
                      margin-bottom: $space-9;
                      padding: $space-9;
          
                      &:hover {
                          background-color: white;
                          color: black;
                          cursor: pointer;
                      }
                  }
              }
          }
        }

        .MoviePage_quickSearch {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-image: linear-gradient(326deg, #168ff1 0%, #2164e0 74%);  
            padding: $space-9;    
            margin: $space-8;
            border-radius: 10px;
            box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.10);
            width: $space-1*4;
            margin-left: 0;
            font-size: $space-7*.9;
      
            @include viewport-small-only {
              width: max-content;
            }
      
            .MoviePage_quickSearch_zipInput {
              display: inline-block;
              text-decoration: underline;
              padding-right: $space-9;
            }
      
            .MoviePage_quickSearch_zipLabel {
              display: inline-block;
              text-decoration: underline;
              padding-right: $space-9;
            }
      
            .MoviePage_quickSearch_calendar {
              color: $font-grey;
              border: 2px solid white;
              border-radius: $space-9/2;

              input {
                color: $font-grey;
              }
              svg {
                color: $font-grey;
                line {
                  stroke: $font-grey;
                }
                rect {
                  stroke: $font-grey;
                }
              }
            }
          }



    }
}