@import '../../sass/import.scss';

$card-size: $space-1 + $space-1;

.Scene {
    width: $card-size;
    height: $card-size;
    display: flex;
    flex-direction: column;
    margin: 0 $space-7 $space-7 0;
    perspective: 600px;

}
  
.Card {
    position: relative;
    width: $card-size;
    height: $card-size;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center;
    transition: transform 1s;
    display: inline-block;
}

.Card.isFlipped {
    transform: rotateY(-180deg);
    .Card_face__front {
        opacity: 0;
        transition: opacity .7s ease-in-out;
    }
}
  
.Card_face {
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    width: $card-size;
    height: $card-size;
    line-height: $card-size;
    color: white;
    font-weight: bold;
    font-size: 40px;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    img {
        border-radius: 10px 10px 0px 0px;
        vertical-align: top;
        max-width: 100%;
        max-height: 100%;
        backface-visibility: hidden;
    }

    .Card_title {
        font-weight: 700;
        font-size: $space-7;
        max-width: $card-size - $space-4;
        position: absolute;
        margin: 0;
        margin-top: $space-5+$space-9;
        margin-left: $space-9;
        backface-visibility: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .Card_rating {
        font-weight: 400;
        font-size: $space-8;
        font-style: oblique;
        max-width: $card-size - $space-4;
        position: absolute;
        margin: 0;
        margin-top: $space-5+$space-5;
        margin-left: $space-9;
        backface-visibility: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .Card_overview {
        font-weight: 400;
        font-size: $space-8;
        line-height: $space-7;
        max-width: $card-size - $space-7;
        margin-top: $space-5+$space-5*.75;
        margin-left: $space-9;
        backface-visibility: hidden;
        display: block;/* or inline-block */
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 4em;

    }

    .Card_flip {
        position: absolute;
        z-index: 100;
        pointer-events: all;
        right: -$space-9;
        padding: $space-8;
        // margin-top: -$space-9;
        backface-visibility: hidden !important;

        svg {
            backface-visibility: hidden !important;
        }

        &:hover {
            backface-visibility: hidden;
            svg {
                backface-visibility: hidden;
                color: darkgray;
            }
        }
    }
}
  
.Card_face__front {
    background: $card-bg;
    opacity: 100;
    transition: opacity 1s ease-in-out;
}
  
.Card_face__back {
    background: $card-bg;
    transform: rotateY(180deg);

    .Card_revert {
        position: absolute;
        top: 0;
        right: $space-9/2;
        bottom: $space-9/2;

        &:hover {
            svg {
                color: darkgray;
            }
        }
    }

    .times {
        // height: 100px;
        width: $card-size;
        margin-left: $space-9;
        margin-top: $space-9;
        overflow-y: scroll;

        .theater_block {
            width: 100%;
            margin-bottom: $space-8;

            .times_theater{
                line-height: $space-7;
                font-size: $space-7;
                display: block;
                margin-bottom: $space-9;
            }

            .times_theaterDistance{
                line-height: $space-8;
                font-size: $space-8;
                font-weight: 400;
                vertical-align: middle;
                margin-bottom: $space-9;
            }

            .times_allTimes {
                display: flex;
                justify-content: initial;
                align-items: center;
                flex-wrap: wrap;

                .timeSlot {
                    width: max-content;
                    font-size: $space-8;
                    line-height: $space-9;
                    border: 2px solid white;
                    border-radius: 5px;
                    margin-right: 2px;
                    margin-bottom: $space-9;
                    padding: $space-9;
        
                    &:hover {
                        background-color: white;
                        color: black;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}