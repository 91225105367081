@import '../../sass/import.scss';

.TopBar__displayTags {
    width: 100%;
    display: flex;
    z-index: 10;
    position: fixed;
    background-color: transparent;
    padding: 0;

    margin: $space-5;
    @include viewport-small-only {
        margin: $space-9 $space-8;
    }

    .TopBar_categories {
        width: 80%;
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;
        overflow-y: hidden;
        // hide scrollbar on Chrome in Windows 
        .element::-webkit-scrollbar { width: 0 !important }
        .element { overflow: -moz-scrollbars-none; }
        .element { -ms-overflow-style: none; }


        .TopBar_category{
            margin-right: $space-6;
            padding: $space-9;
            color: white;
            cursor: pointer;

            &.active {
                color: white;
                padding: $space-9;
                border-radius: 10px;
                background-image: linear-gradient(326deg, #168ff1 0%, #2164e0 74%);  
                transition: background-color 1s ease-in-out;
            }

        }
    }

    .TopBar_search {
        position: absolute;
        top: $space-9;
        right: $space-2;
        color: white;

        @include viewport-small-only {
            top: $space-8;
            right: $space-5;
        }
    }


}

.__visible {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(34,34,34,0.75);
    width: 100%;
    padding: $space-5;
    margin: 0;
    transition: background-color .2s ease-in-out;

    @include viewport-small-only {
        padding: $space-9 $space-8;
        margin: 0;
      }

    .TopBar_categories {
        width: 80%;
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;
        overflow-y: hidden;

        .TopBar_category{
            margin-right: $space-6;

            &.selected {
                border: 2px solid orange;
            }
        }
    }


    .TopBar_search {
        position: absolute;
        top: $space-5+$space-9;
        right: $space-3;

        @include viewport-small-only {
            right: $space-7+$space-9;
            top: $space-8+$space-9;
        }
    }

}

.TopBar__displaySearch {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: fixed;
    background-color: transparent;
    padding: 0;
    transition: padding .2s ease-in-out;
    background: transparent;
    transition: background .15s ease-in-out;

    padding: $space-8;
    @include viewport-small-only {
      padding: $space-6 0 $space-9 0;
    }

   .TopBar_search_searchRow {
        width: 100%;
        display: flex;
        margin-bottom: $space-7;
        z-index: 3;

        .TopBar_search {
            vertical-align: middle;
            display: flex;
            align-items: center;
            width: 100%;
            border-radius: 20px;

            input {
                background-color: $card-bg;
                padding-left: $space-8;
                color: white;
                width: 95%;
                height: 100%;
                border-radius: 20px;
                font-size: $space-7;
                font-family: Inter;
            }
            
            .TopBar_search_icon {
                position: absolute;
                right: $space-6;
            }
        }
   }
   .TopBar_search_tagRow {
        display: flex;
        justify-content: center;
        z-index: 3;

        @include viewport-small-only {
            justify-content: space-around;
        }

        .TopBar_datePick {
            padding: $space-9;
        }

        .active {
            color: white;
            padding: $space-9;
            border-radius: 10px;
            background-image: linear-gradient(326deg, #168ff1 0%, #2164e0 74%);  
            transition: background-color 1s ease-in-out;
        }
    }
}

.__fixedSearch {
    position: fixed;
    z-index: 101 !important;
    transition: background .15s ease-in-out;
}

.__fixedSearch::after{
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: $space-1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(34,34,34,0.75);
    z-index: 2;
    position: absolute;
}